<template>
  <page-layout title="Support" linkDefault="/" :backButton="true">

    <div class="flex flex-col items-center justify-center ml-8 mr-8 mt-5 mb-10">
      <p class="text-left font-poppins font-normal text-sm leading-5 mb-11">
        The Tutoruu Fam prioritizes YOUR wellbeing, first and foremost. Let us know how we can help at anytime, We love
        to chat!
        Send us a message on Facebook Messenger by clicking the button below
      </p>

      <a target="_blank" href="http://m.me/TUTORUU?ref=support">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/2048px-Facebook_Messenger_logo_2020.svg.png"
          class="w-20" alt="">
      </a>
    </div>

    <div class="flex flex-col ml-8 mr-8 items-start justify-start mb-4 ">
      <p class="text-left font-poppins font-normal text-sm leading-5">Or by sending us an email.</p>
    </div>

    <div class="flex flex-col items-start justify-start ml-8 mr-8 mb-3">
      <p class="font-poppins font-medium text-base leading-6">Subject</p>
    </div>
    <div class="flex flex-col items-center justify-center ml-8 mr-8 mb-11">
      <textarea v-model="subject" class="
          h-12
          border-2 border-orange
          bg-white
          rounded-3xl
          placeholder-gray-500
          w-full
          p-2.5
          ml-8
          mr-8
        " />
    </div>

    <div class="flex flex-col items-start justify-start ml-8 mr-8 mb-4">
      <p class="font-poppins font-medium text-sm leading-5">Message</p>
    </div>

    <div class="flex flex-col items-center justify-center ml-8 mr-8 mb-11">
      <textarea v-model="message" class="
          h-40
          border-2 border-orange
          bg-white
          rounded-3xl
          placeholder-gray-500
          w-full
          p-2.5
          ml-8
          mr-8
        " />
    </div>
    <div class="px-7 mt-4 w-full  bottom-20">
        <Button @click="submit()" shape="full">Submit!</Button>
      </div>
  </page-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Button from '../../components/Feed/Button.vue';
import pageLayout from "../../components/base/pageLayout.vue";
export default {
  components: { pageLayout, Button },
  data() {
      return {
          message: '',
          subject: '',
          submitDisabled: false,
      }
  },
  computed: {
    ...mapState(["username"]),
  },
  methods: {
    ...mapActions(['help_me']),
      submit() {
            this.help_me({message: this.message, subject: this.subject})
            this.$router.push(`/help-is-on-the-way`)
          }
      
  }
};
</script>
